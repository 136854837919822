import React from 'react'
import Helmet from 'react-helmet'
import { t } from '../iso/vi'
import { colors } from './styles/style-color'

export const mobileViewPortContent =
  'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no, minimal-ui'

export const Head = () => {
  return (
    <Helmet
      title={`${t('meta.title')} - ${t('meta.description')}`}
      meta={[
        { name: 'viewport', content: mobileViewPortContent },
        { name: 'description', content: t('meta.description') },
        { name: 'theme-color', content: colors.primary },

        // social
        { property: 'og:title', content: `${t('meta.title')}` },
        { property: 'og:description', content: t('meta.description') },
        { property: 'twitter:card', content: 'summary' },
        {
          property: 'twitter:image',
          content: 'https://lazico.com/lazico_thumbnail.jpg',
        },
        {
          property: 'og:image',
          content: 'https://lazico.com/lazico_thumbnail.jpg',
        },
        {
          property: 'og:image:secure_url',
          content: 'https://lazico.com/lazico_thumbnail.jpg',
        },
        { property: 'og:site_name', content: `${t('meta.site_name')}` },
      ]}
      link={[
        // PWA & mobile
        { rel: 'manifest', href: '/manifest.json' },
        { rel: 'apple-touch-icon', href: '/favicon.png' },

        {
          rel: 'icon',
          type: 'image/png',
          sizes: 'any',
          href: '/favicon.png',
        },

        // styles
        {
          rel: 'stylesheet',
          type: 'text/css',
          href: '/stylesheets/main.css',
        },
        {
          rel: 'stylesheet',
          type: 'text/css',
          href: '/stylesheets/antd.css',
        },

        {
          href: 'https://fonts.googleapis.com/css?family=Heebo:300&display=swap&subset=hebrew',
          rel: 'stylesheet',
          type: 'text/css',
        },
        // {
        //   href:
        //     "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css",
        //   rel: "stylesheet",
        //   type: "text/css"
        // }
      ]}
    />
  )
}
