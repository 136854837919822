// Security precaution
;(window as any).eval = global.eval = (payload: string) => {
  const error = new Error(`This app does not allow window.eval().`)
  Object.assign(error, { payload })

  throw error
}

import 'react-app-polyfill/stable'
import 'resize-observer-polyfill/dist/ResizeObserver.global'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

import localforage from 'localforage'
// import { ComponentType } from 'react'
// import { AppRegistry } from 'react'
import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'

import { App } from './app'

localforage.config({
  name: 'lazico',
  size: 100 * 1024 * 1024,
})
/*
const render = (AppComponent: ComponentType) => {
  AppRegistry.registerComponent('lazico', () => AppComponent)
  AppRegistry.runApplication('lazico', {
    rootTag: document.getElementById('root'),
  })
}

render(App)*/

const root = document.getElementById('root')

ReactDOM.render(<App {...root?.dataset} />, root)

/*
if (typeof module !== 'undefined' && (module as any).hot) {
  ;(module as any).hot.accept('@devhub/components/src/components/App', () => {
    const NewApp = require('@devhub/components/src/components/App').App
    render(NewApp)
  })
}
*/
