export const vi: { [key: string]: string } = {
  'meta.title': 'Lazico',
  'meta.description': 'Nông Nghiệp thông minh',
  'meta.site_name': 'Ứng dụng của Lazico',
  'topbar.brand': 'Lazico App',
  'topbar.connect': 'Connect',
  'topbar.sms': 'SMS',
  'topbar.home': 'Thiết bị',
  'topbar.facebook': 'Facebook',
  'topbar.videos': 'Videos',
  'topbar.desktop': 'Desktop',
  'not_found.bar': 'Không Thấy Trang',
  'not_found.title': 'Oops!',
  'not_found.info': 'Trang bạn tìm kiếm không tồn tại...',
  app_price: 'Free',
  'auth/forgot_password': 'Forgot Password',
  'auth/reset_password': 'Reset Password',
  'auth/button_submit': 'Submit',
  'profile.home': 'home',
  'profile.settings': 'settings',
  'auth/sign_out': 'sign out',
  'auth/weak-password': 'Weak Password',
  'auth/email-already-in-use': 'Email already used',
  'auth/user-not-found': 'User not found',
  'auth/wrong-password': 'Wrong password',
  'auth/user-disabled': 'User disabled',
  'configs.app_price': 'Miễn phí',
  'configs.your_city': 'Hà Nội',
  'configs.made_by': 'Phát triển bởi',
  'config.in': ' ',
  'feature.0.title': 'Tối ưu trải nghiệm người dùng',
  'feature.1.title': 'An toàn & Bảo mật',
  'feature.2.title': 'Có ngay trên App Store',
  'feature.0.description': 'Đơn giản dễ sử dụng ngay cả với bà con nông dân.',
  'feature.1.description': 'Bảo mật với vân tay hoặc mật khẩu.',
  'feature.2.description': 'Tải ứng dụng và dùng thử chỉ bằng 1 click.',
  'tools.swap_title': 'Token Swap',
  'tools.swap_token': 'Token',
  'tools.swap_address': 'Address',
  'tools.swap_direction': 'Swap Direction',
  'tools.eth_receive': 'Eth Receive Address',
  'tools.bnb_receive': 'BNB Receive Address',
  'tools.eth_addr_eg': 'eg: 0x0dE0BCb0703ff8F1aEb8C892eDbE692683bD8030',
  'tools.bnb_addr_eg': 'eg: bnb1mmxvnhkyqrvd2dpskvsgl8lmft4tnrcs97apr3',
  'tools.addr_error': 'Address is invalid.',
  'tools.convert': 'Convert',
  'tools.swap_from': 'Swap From',
  'tools.swap_to': 'Swap To',
  'tools.select_a_token': 'Select a token',
  'tools.balance': '${from} balance: ',
  'desktop_app.desc': 'It is now avalable in your laptop!',
  'desktop_app.download': 'Download Lazico Desktop App',
  'captcha.loading_captcha': 'Loading captcha...',
  'captcha.incorrect_captcha': 'Wrong captcha',
  'captcha.unknown_error': 'Something went wrong. Please try again later.',
}

export const t = (key: string) => vi[key] || key
