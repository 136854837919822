import React, { Component } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Link, useLocation } from 'react-router-dom'
import { styled, StyleObject } from 'styletron-react'
import { TYPE_PAGE } from '../home/home'
import { t } from '../iso/vi'
import { configs } from '../site-config'
import { CommonMargin } from './common-margin'
import { Icon } from './icon'
import { Cross } from './icons/cross.svg'
import { Hamburger } from './icons/hamburger.svg'
import { transition } from './styles/style-animation'
import { colors } from './styles/style-color'
import { media, PALM_WIDTH } from './styles/style-media'
import { contentPadding } from './styles/style-padding'

export const TOP_BAR_HEIGHT = 75

interface IRouterParam {
  name: string
  pageType: string
  displayMenu: boolean
}

export const params: { [key: string]: IRouterParam } = {
  '/': {
    name: t('topbar.sms'),
    pageType: TYPE_PAGE.PAGE_SMS,
    displayMenu: false,
  },
  '/iotapp': {
    name: t('topbar.connect'),
    pageType: TYPE_PAGE.PAGE_CONNECT,
    displayMenu: true,
  },
  '/sms': {
    name: t('topbar.sms'),
    pageType: TYPE_PAGE.PAGE_SMS,
    displayMenu: true,
  },
}

interface State {
  displayMobileMenu: boolean
}

interface Props {}

export class TopBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      displayMobileMenu: false,
    }
  }

  public componentDidMount(): void {
    window.addEventListener('resize', () => {
      if (
        document.documentElement &&
        document.documentElement.clientWidth > PALM_WIDTH
      ) {
        this.setState({
          displayMobileMenu: false,
        })
      }
    })
  }

  public displayMobileMenu = () => {
    this.setState({
      displayMobileMenu: true,
    })
  }

  public hideMobileMenu = () => {
    this.setState({
      displayMobileMenu: false,
    })
  }

  public renderMenu = () => {
    return (
      <>
        <a
          rel="noreferrer"
          // @ts-ignore
          style={menuItem}
          target={'_blank'}
          key={0}
          href="https://lazico.vn/"
          onClick={this.hideMobileMenu}
        >
          {t('topbar.home')}
        </a>
        <a
          rel="noreferrer"
          // @ts-ignore
          style={menuItem}
          target={'_blank'}
          key={1}
          href={`https://www.youtube.com/channel/${configs.youtube_username}`}
          aria-label="Youtube"
          onClick={this.hideMobileMenu}
        >
          {t('topbar.videos')}
        </a>
        <a
          rel="noreferrer"
          // @ts-ignore
          style={menuItem}
          target={'_blank'}
          key={2}
          aria-label="Facebook"
          href={`https://facebook.com/${configs.facebook_username}`}
          onClick={this.hideMobileMenu}
        >
          {t('topbar.facebook')}
        </a>
      </>
    )
  }

  public renderMobileMenu = () => {
    if (!this.state.displayMobileMenu) {
      return null
    }

    return (
      <OutsideClickHandler onOutsideClick={this.hideMobileMenu}>
        <Dropdown>{this.renderMenu()}</Dropdown>
      </OutsideClickHandler>
    )
  }

  public render(): JSX.Element {
    const displayMobileMenu = this.state.displayMobileMenu

    return (
      <div>
        <Bar>
          <Flex>
            <Logo />
            <PageTypeList />
            <CommonMargin />
          </Flex>
          <Flex>
            <Menu>{this.renderMenu()}</Menu>
          </Flex>
          <HamburgerBtn
            onClick={this.displayMobileMenu}
            displayMobileMenu={displayMobileMenu}
          >
            <Hamburger />
          </HamburgerBtn>
          <CrossBtn displayMobileMenu={displayMobileMenu}>
            <Cross />
          </CrossBtn>
        </Bar>
        <BarPlaceholder />
        {this.renderMobileMenu()}
      </div>
    )
  }
}

const Bar = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: `${TOP_BAR_HEIGHT}px`,
  height: `${TOP_BAR_HEIGHT}px`,
  backgroundColor: colors.nav01,
  color: colors.white,
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100%',
  'z-index': '70',
  ...contentPadding,
  boxSizing: 'border-box',
})

const BarPlaceholder = styled('div', (_: React.CSSProperties) => {
  const height = TOP_BAR_HEIGHT / 2
  return {
    display: 'block',
    padding: `${height}px ${height}px ${height}px ${height}px`,
    backgroundColor: colors.nav01,
  }
})

const HamburgerBtn = ({
  displayMobileMenu,
  children,
  onClick,
}: {
  displayMobileMenu: boolean
  children: JSX.Element[] | JSX.Element
  onClick: (event: any) => void
}) => {
  const Styled = styled('div', {
    ':hover': {
      color: colors.primary,
    },
    display: 'none!important',
    [media.palm]: {
      display: 'flex!important',
      ...(displayMobileMenu ? { display: 'none!important' } : {}),
    },
    cursor: 'pointer',
    justifyContent: 'center',
  })
  return <Styled onClick={onClick}>{children}</Styled>
}

function CrossBtn({
  displayMobileMenu,
  children,
}: {
  displayMobileMenu: boolean
  children: JSX.Element[] | JSX.Element
}): JSX.Element {
  const Styled = styled('div', {
    ':hover': {
      color: colors.primary,
    },
    display: 'none!important',
    [media.palm]: {
      display: 'none!important',
      ...(displayMobileMenu ? { display: 'flex!important' } : {}),
    },
    cursor: 'pointer',
    justifyContent: 'center',
    padding: '5px',
  })
  return <Styled>{children}</Styled>
}

const LogoWrapper = styled('a', {
  width: '150px',
  height: '100%',
})

function Logo(): JSX.Element {
  return (
    <LogoWrapper>
      <Link to={'/'}>
        <Icon url={'/logo.png'} width={'auto'} height={'75px'} />
      </Link>
    </LogoWrapper>
  )
}

const menuItem: StyleObject = {
  color: colors.white,
  marginLeft: '25px',
  padding: '16px 0 16px 0',
  textDecoration: 'none',
  ':hover': {
    color: colors.primary,
  },
  transition,
  fontWeight: 'bold',
  [media.palm]: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '16px 0 16px 0',
    borderBottom: '1px #EDEDED solid',
  },
}
const BrandText = styled('a', {
  ...menuItem,
  cursor: 'pointer',
  padding: '0px',
  marginLeft: '5px',
  [media.palm]: {},
})

const Flex = styled('div', (_: React.CSSProperties) => ({
  flexDirection: 'row',
  display: 'flex',
  boxSizing: 'border-box',
}))

const Menu = styled('div', {
  display: 'flex!important',
  [media.palm]: {
    display: 'none!important',
  },
})

const Dropdown = styled('div', {
  backgroundColor: colors.nav01,
  display: 'flex',
  flexDirection: 'column',
  ...contentPadding,
  position: 'fixed',
  top: TOP_BAR_HEIGHT,
  'z-index': '1',
  width: '100vw',
  height: '100vh',
  alignItems: 'flex-end!important',
  boxSizing: 'border-box',
})

const PageTypeList = () => {
  const location = useLocation()
  const pathName = location.pathname
  const currentPageType = params[pathName]?.pageType
  return (
    <>
      {Object.entries(params)
        .filter(([p, { displayMenu }]) => displayMenu)
        .map(([path, { pageType: pageDisplay, name }], index) => (
          <div
            key={`menu-page-${pageDisplay}`}
            style={
              index === 0
                ? { marginTop: -10 }
                : { position: 'relative', top: 10, right: 50, height: 0 }
            }
          >
            <BrandText
              style={currentPageType === pageDisplay ? {} : { opacity: 0.5 }}
            >
              <Link to={path}>{name}</Link>
            </BrandText>
          </div>
        ))}
    </>
  )
}
