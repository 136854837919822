import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Client as Styletron } from 'styletron-engine-atomic'
import {
  DebugEngine,
  Provider as StyletronProvider,
  styled,
} from 'styletron-react'
import { ErrorPage } from './common/error-page'
import { FOOTER_ABOVE } from './common/footer'
import { Head } from './common/head'
import ScrollToTop from './common/scroll-top'
import { params, TopBar } from './common/top-bar'
import { Home } from './home/home'
import { t } from './iso/vi'

const debug = process.env.NODE_ENV === 'production' ? void 0 : new DebugEngine()

// 1. Create a client engine instance
const engine = new Styletron()

export const App = () => (
  <BrowserRouter>
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <RootStyle>
        <Head />
        <TopBar />
        <div style={FOOTER_ABOVE}>
          <ScrollToTop />
          <Routes>
            {Object.entries(params).map(([path, { pageType }]) => (
              <Route
                key={`route-${pageType}`}
                path={path}
                element={<Home pageDisplay={pageType} />}
              />
            ))}
            <Route
              path="*"
              element={
                <ErrorPage
                  bar={t('not_found.bar')}
                  title={t('not_found.title')}
                  info={t('not_found.info')}
                />
              }
            />
          </Routes>
        </div>
      </RootStyle>
    </StyletronProvider>
  </BrowserRouter>
)

const RootStyle = styled('div', () => ({}))
