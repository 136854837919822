import { t } from './iso/vi'

export const getConfigs = () => ({
  // App Info
  appstore_link: 'https://github.com/hunglmtb/tuoitieu/raw/master/lazico.apk', // Enter App Store URL.
  appstore_mirror_link:
    'https://github.com/hunglmtb/tuoitieu/raw/master/lazico.apk',
  playstore_link: 'https://github.com/hunglmtb/tuoitieu/raw/master/lazico.apk', // Enter Google Play Store URL.
  iosStore_link: 'itms-apps://itunes.apple.com/app/id1447940257', // Enter App Store
  playStore_link_app_connect:
    'https://play.google.com/store/apps/details?id=com.lazico.data',
  iosStore_link_app_connect: 'itms-apps://itunes.apple.com/app/id1579294803',
  app_connect_version: 'v.15',
  google_analytics_ID: 'UA-xxxxxxxx-x', // Enter Google Analytics ID or ""
  // presskit_download_link: "https://imedadel.me", // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: 'screenshot', // "screenshot" or "video"
  app_url: 'https://github.com/hunglmtb/tuoitieu/raw/master/lazico.apk', // Domain of your website without path_prefix.
  path_prefix: '/', // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: t('topbar.brand'),
  app_connect_name: 'Lazico Connect',
  app_price: t('configs.app_price'),
  app_description: t('meta.description'),
  app_keywords: ['automatic', 'onefx', 'app', 'landing page'],
  index_link: 'https://lazico.vn',
  app_version: 'v1.8',

  // Personal Info
  your_name: 'Lazico',
  your_link: 'https://lazico.vn/',
  your_city: t('configs.your_city'),
  in: t('config.in'),
  email_address: 'info@lazico.com',
  linkedin_username: null,
  facebook_username: 'LazicoVN',
  instagram_username: '',
  twitter_username: '',
  github_username: '',
  youtube_username: 'UClrRH6kNteZRbCYMbgoxAqg',
  made_by: t('configs.made_by'),
  forum_link: '',

  // Features List
  features: [
    {
      title: 'feature.0.title', // "Great User Experience"
      description: 'feature.0.description',
      fontawesome_icon_name: 'magic',
    },
    {
      title: 'feature.1.title', // "Safe & Secure",
      description: 'feature.1.description',
      fontawesome_icon_name: 'mobile',
    },
    {
      title: 'feature.2.title', // "Integrated App Store",
      description: 'feature.2.description',
      fontawesome_icon_name: 'play-circle',
    },
  ],
  header_background: 'rgba(0, 0, 0, 0.1)',
  topbar_title_color: '#ffffff',
  cover_overlay_color_rgba: 'rgba(54, 59, 61, 0.8)',
  device_color: 'black', // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: 'ffffff',
  primary_text_color: '#000',
  content_width: '1170px',
  font: `light-heebo`,
  link_color: '#1d63ea',
  app_title_color: '#ffffff',
  app_price_color: '#ffffff',
  app_description_color: '#ffffff',
  feature_title_color: '#000000',
  feature_text_color: '#666666',
  feature_icons_foreground_color: '#1d63ea',
  feature_icons_background_color: '#e6e6e6',
  social_icons_foreground_color: '#666666',
  social_icons_background_color: '#e6e6e6',
  footer_text_color: '#666666',

  presskit_download_link: '',
})

export const configs = getConfigs()
